import React from 'react';

import type { ButtonIconVariants } from 'Theme/types/elements.type';
import type { Props as ButtonProps } from '..';

import { Container, Icon as IconStyled } from './Icon.styled';

export interface Props extends Omit<ButtonProps, 'variant'> {
    icon: React.ElementType;
    variant?: ButtonIconVariants;
}

const Icon: React.FC<Props> = props => {
    const { label, children, icon, variant = 'accentSix', ...rest } = props;

    return (
        <Container {...rest} $variant={variant}>
            {label ? label : children}
            <IconStyled as={icon} $variant={variant} />
        </Container>
    );
};

export default Icon;
