/* eslint-disable react/no-unused-prop-types */
import React from 'react';

import {
    Facebook,
    Linkedin,
    Youtube,
    Twitter,
    Instagram,
    Flickr,
    Soundcloud,
    Tiktok,
    X
} from '@solent-university/solent-icons';

import { List, Item, StyledLink, Icon } from './SocialIcons.styled';

const icons = {
    facebook: Facebook,
    linkedin: Linkedin,
    youtube: Youtube,
    twitter: Twitter,
    instagram: Instagram,
    flickr: Flickr,
    soundcloud: Soundcloud,
    tiktok: Tiktok,
    x: X
};

export interface Props {
    facebook?: string;
    linkedin?: string;
    youtube?: string;
    twitter?: string;
    instagram?: string;
    flickr?: string;
    soundcloud?: string;
    tiktok?: string;
    x?: string;
}

/* You need more than one */

const SocialIcons: React.FC<Props> = props => {
    return (
        <List>
            {Object.keys(props).map(
                key =>
                    props[key] && (
                        <Item key={key}>
                            <StyledLink
                                href={props[key]}
                                aria-label={key}
                                target="_blank"
                                rel="noopener nofollow"
                            >
                                <Icon as={icons[key.toLowerCase()]} />
                            </StyledLink>
                        </Item>
                    )
            )}
        </List>
    );
};

export default SocialIcons;
