import { PageEntry } from 'contensis-delivery-api';

import colors from 'Theme/lightTheme/colors';

import { sanitizePhoneNumber } from './string';

import type { LinkProps } from 'Elements/Link';
import type { ArrowLinkProps } from 'Elements/Link/Arrow';
import type { LinkButtonProps } from 'Elements/Button';
import type { Props as MetadataProps } from 'Components/Metadata';
import type { BreadcrumbsProps } from 'Components/Breadcrumbs';
import type { VideoProps } from 'Components/Video';
import type { ButtonVariants, LinkVariants } from 'Theme/types/elements.type';
import type { MakeWavesHeroBannerProps } from 'Modules/HeroBanner/MakeWaves';

interface CMSUrl {
    url?: string;
    path?: string;
    sys?: { uri?: string };
    link?: string;
    email?: string;
    emailAddress?: string;
    telephoneNumber?: string;
    entry?: {
        sys?: {
            uri?: string;
        };
    };
}

interface LinkData {
    sys?: { uri?: string };
    label: string;
    ariaLabel?: string;
    link?: CMSUrl;
    withoutNextLink?: boolean;
    openInNewTab?: boolean;
    openInNewWindow?: boolean;
    id?: string;
}

interface ArrowLinkData extends LinkData {
    iconOutline?: boolean;
    iconColor?: string;
}

export default class CMSParser {
    static link(data: LinkData): LinkProps {
        const linkData: LinkProps = {
            label: data.label,
            ariaLabel: data.ariaLabel || undefined,
            href: data.link?.email
                ? `mailto:${data.link.email}`
                : data.link?.emailAddress
                ? `mailto:${data.link.emailAddress}`
                : data.link?.telephoneNumber
                ? `tel:${sanitizePhoneNumber(data.link.telephoneNumber)}`
                : data.link?.url ||
                  data.link?.path ||
                  data.link?.entry?.sys?.uri ||
                  data.link?.link ||
                  data.link?.sys?.uri ||
                  data.sys?.uri ||
                  '/',
            openInNewTab: data.openInNewTab || false,
            withoutNextLink: data.withoutNextLink || false
        };

        if (data.id) {
            linkData.id = data.id;
        }

        return linkData;
    }

    static arrowLink(data: ArrowLinkData): ArrowLinkProps {
        const arrowLinkData: ArrowLinkProps = {
            label: data.label,
            ariaLabel: data.ariaLabel || undefined,
            href: data.link?.email
                ? `mailto:${data.link.email}`
                : data.link?.url ||
                  data.link?.path ||
                  data.link?.entry?.sys?.uri ||
                  data.link?.link ||
                  data.link?.sys?.uri ||
                  data.sys?.uri ||
                  '/',
            openInNewTab: data.openInNewTab || false,
            iconOutline: data.iconOutline || false,
            iconColor: data.iconColor || undefined,
            withoutNextLink: data.withoutNextLink || false
        };

        if (data.id) {
            arrowLinkData.id = data.id;
        }

        if (data.iconOutline) {
            arrowLinkData.iconOutline = data.iconOutline;
        }

        return arrowLinkData;
    }

    static linkWithoutNextLink(data: LinkData): LinkProps {
        return { ...CMSParser.link(data), withoutNextLink: true };
    }

    static linkButton(data: LinkData): LinkButtonProps {
        const linkData: LinkButtonProps = {
            label: data.label,
            ariaLabel: data.ariaLabel || undefined,
            href: data.link?.email
                ? `mailto:${data.link.email}`
                : data.link?.emailAddress
                ? `mailto:${data.link.emailAddress}`
                : data.link?.url ||
                  data.link?.path ||
                  data.link?.entry?.sys?.uri ||
                  data.link?.link ||
                  data.sys?.uri ||
                  data.sys?.uri ||
                  '/',
            openInNewTab: data.openInNewTab || data.openInNewWindow
        };

        if (data.id) {
            linkData.id = data.id;
        }

        return linkData;
    }

    static image(
        data?: {
            uriPrefix?: string;
            asset?: { sys?: { uri?: string } };
            uri?: string;
            altText?: string;
        },
        uriPrefix = '/cms'
    ): {
        src: string;
        alt: string;
    } {
        return {
            src: uriPrefix + (data?.asset?.sys?.uri || data?.uri || ''),
            alt: data?.altText || ''
        };
    }

    static metadata(data: {
        title: string;
        pageMetadata: {
            customTitle?: string;
            description: string;
            keywords: string;
            noIndexing?: boolean;
            canonical?: string;
        };
        sys: {
            uri: string;
        };
    }): MetadataProps {
        const { title, sys, pageMetadata } = data;
        const { customTitle, description, keywords, noIndexing, canonical } = pageMetadata;

        return {
            title: customTitle && customTitle !== '' ? customTitle : title,
            description,
            keywords,
            noIndex: noIndexing,
            canonical,
            path: sys.uri
        };
    }

    static video(data: {
        title: string;
        thumbnail: { asset: { sys: { uri: string } }; altText: string };
        link: string;
    }): VideoProps {
        const { title, thumbnail, link } = data;

        return {
            video: {
                src: link
            },
            image: { src: `/cms${thumbnail.asset.sys.uri}`, alt: thumbnail.altText },
            title: title
        };
    }

    static transcriptEmbed(data: PageEntry): VideoProps {
        return {
            video: {
                src: data.link,
                title: data.videoTitle
            },
            image: {
                src: `/cms${data.thumbnail.asset.sys.uri}`,
                alt: data.thumbnail.altText
            },
            title: data.title,
            caption: data.caption,
            transcript:
                //checking values are present & that table row is array, not null as conditional
                data.tableHeadingsVideoTranscript && Array.isArray(data.tableRowVideoTranscript)
                    ? {
                          tableHeadings: [
                              data.tableHeadingsVideoTranscript.timestamp,
                              data.tableHeadingsVideoTranscript.transcript,
                              data.tableHeadingsVideoTranscript.audioDescription
                          ],
                          tableRows: data.tableRowVideoTranscript.map(row => [
                              row.timestamp,
                              row.transcript,
                              row.audioDescription
                          ])
                      }
                    : undefined,
            transcriptUrl: data.sys.uri
        };
    }

    static breadcrumbs(
        data: { entry?: { title: string }; displayName: string; path: string; id: string }[]
    ): BreadcrumbsProps {
        return {
            items: data.map(item => ({
                id: item.id,
                label: item.entry?.title ? item.entry.title : item.displayName,
                href: item.entry ? item.path : undefined
            }))
        };
    }

    static heroBannerWave(data: {
        preHeading?: string;
        heading: string;
        postHeading?: string;
        text?: string;
        label: string;
        ariaLabel?: string;
        link?: CMSUrl;
        openInNewTab?: boolean;
        id?: string;
        buttonColourVariant?: string;
        backgroundColour?: string;
        textColour?: string;
    }): MakeWavesHeroBannerProps {
        const backgroundColour = data.backgroundColour
            ? this.getThemeColour(data.backgroundColour)
            : colors.primary.hex;

        const textColour = data.textColour
            ? this.getThemeColour(data.textColour)
            : colors.secondary.hex;

        let buttonVariant = 'primary' as ButtonVariants;
        switch (data.buttonColourVariant) {
            case 'Primary - Red text, white arrow':
                buttonVariant = 'primary';
                break;
            case 'Secondary - Blue text, white arrow':
                buttonVariant = 'secondary';
                break;
            case 'Outline - Red text and border, transparent background':
                buttonVariant = 'outline';
                break;
            default:
                break;
        }

        return {
            inner: {
                preHeading: data.preHeading,
                heading: data.heading,
                postHeading: data.postHeading,
                text: data.text,
                button: data.label
                    ? {
                          ...this.linkButton({
                              id: data.id,
                              label: data.label,
                              ariaLabel: data.ariaLabel,
                              openInNewTab: data.openInNewTab,
                              link: data.link
                          }),
                          variant: buttonVariant
                      }
                    : undefined
            },
            backgroundColour: backgroundColour,
            textColour: textColour
        };
    }

    static getLinkVariant(variantString: string): string | undefined {
        let linkVariant: LinkVariants | undefined = undefined;
        switch (variantString) {
            case 'Default - Red - #CD1423':
                linkVariant = 'default';
                break;
            case 'Text - Blue - #1D2136':
                linkVariant = 'text';
                break;
            case 'Secondary - White - #FFFFFF':
                linkVariant = 'secondary';
                break;
            default:
                break;
        }
        return linkVariant;
    }

    static getThemeColour(colourString: string): string | undefined {
        let colour: string | undefined = undefined;
        switch (colourString) {
            case 'Primary red - #CD1423':
                colour = colors.primary.hex;
                break;
            case 'Navy blue - #1D2136':
                colour = colors.tertiary.hex;
                break;
            case 'White - #FFFFFF':
                colour = colors.secondary.hex;
                break;
            case 'Black - #000000':
                colour = colors.secondaryContrast.hex;
                break;
            case 'Off black - #191919':
                colour = colors.secondary.tints[9].hex;
                break;
            case 'Tint grey - #E8E9EA':
                colour = colors.secondary.tints[1].hex;
                break;
            case 'Dark grey - #333333':
                colour = colors.secondary.tints[8].hex;
                break;
            case 'Cyan - #01C3DE':
                colour = colors.accents[1].hex;
                break;
            case 'Accent yellow - #FFC11B':
                colour = colors.accents[3].hex;
                break;
            case 'Accent blue - #01C3DE':
                colour = colors.accents[1].hex;
                break;
            case 'Accent violet - #D82F89':
                colour = colors.accents[8].hex;
                break;
            case 'Accent green - #C6C745':
                colour = colors.accents[4].hex;
                break;
            case 'Accent Indigo - #0F435F':
                colour = colors.accents[5].hex;
                break;
            case 'Accent orange - #FF4D00':
                colour = colors.accents[6].hex;
                break;
            case 'Accent Teal - #1F988A':
                colour = colors.accents[7].hex;
                break;
            case 'Accent Burgundy - #531623':
                colour = colors.accents[9].hex;
                break;
            case 'Accent petrol green - #083237':
                colour = colors.accents[10].hex;
                break;
            case 'Accent Pink - #D82F89':
                colour = colors.accents[8].hex;
                break;
            case `Accent Payne's gray - #396379`:
                colour = colors.accents[16].hex;
                break;
            case 'Accent Keppel - #4CADA1':
                colour = colors.accents[17].hex;
                break;
            case 'Accent Crayola orange - #FF7133':
                colour = colors.accents[18].hex;
                break;
            case 'Accent Amethyst - #A869F6':
                colour = colors.accents[19].hex;
                break;
            case 'Accent Sunglow - #FFCD49':
                colour = colors.accents[20].hex;
                break;
            case 'Accent Rose - #E059A1':
                colour = colors.accents[21].hex;
                break;
            case 'Accent Citron - #D1D26A':
                colour = colors.accents[22].hex;
                break;
            case 'Accent Feldgrau - #49676A':
                colour = colors.accents[23].hex;
                break;
            case 'Jet - #333333':
                colour = colors.campaigns.clearing.secondary.hex;
                break;
            default:
                break;
        }
        return colour;
    }

    static getWaveOrientation(orientationString: string): string | undefined {
        let orientation: 'xflipped' | 'yflipped' | 'default' | undefined = undefined;
        switch (orientationString) {
            case 'Horizontally flipped':
                orientation = 'xflipped';
                break;
            case 'Veritcally flipped':
                orientation = 'yflipped';
                break;
            case 'default':
                orientation = 'default';
                break;
            default:
                break;
        }
        return orientation;
    }
}
