import React, { useState, useEffect, useMemo } from 'react';
import { nanoid } from 'nanoid';
import cmsParser from 'Common/util/cmsParser';
import slugify from 'slugify';
import qs from 'qs';
import { useRouter } from 'next/router';
import Script from 'next/script';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import htmlParser from 'html-react-parser';

import type { Entry } from 'contensis-delivery-api/lib/models/Entry';

import ContentSkip from 'Elements/Button/ContentSkip';

import SiteAlert from 'Components/SiteAlert';
import ScrollToTop from 'Components/ScrollToTop';
import PreviewStatus from 'Components/PreviewStatus';

import Masthead from 'Modules/Masthead';
import TopBar from 'Modules/TopBar';
import Footer from 'Modules/Footer';

import Banner from 'Campaigns/Clearing/Banner';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export let landbot: any = undefined;

const Main: React.FC<{
    logo?: 'white' | 'red';
    initialData?: Record<string, Entry | null>;
}> = props => {
    const { children, logo = 'white', initialData } = props;
    const { siteNavigation, siteFooter, clearingBanner, siteAlert } = initialData || {};
    const { isPreview, pathname } = useRouter();
    const [testMode, setTestMode] = useState(false);

    const setupLandbot = () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        landbot = new (window as any).Landbot.Livechat({
            configUrl:
                'https://storage.googleapis.com/landbot.pro/v3/H-1454814-B2S89CZLN6I36RVZ/index.json'
        });
        landbot.core.events.on('widget_open', function () {
            window.localStorage.setItem('landbot_open_status', JSON.stringify('true'));
        });
        landbot.core.events.on('widget_close', function () {
            window.localStorage.setItem('landbot_open_status', JSON.stringify('false'));
        });
        const landbotStaus = window.localStorage.getItem('landbot_open_status') || undefined;
        if (landbotStaus && JSON.parse(landbotStaus) === 'true') {
            landbot.core.events.on('load', function () {
                landbot.open();
            });
        }
    };

    useEffect(() => {
        const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        setTestMode(query.testMode === 'true' || false);
    }, []);

    const dynamicIcon = (iconName: string): React.ElementType =>
        useMemo(() => {
            return dynamic(() =>
                import('@solent-university/solent-icons').then(icons => icons[iconName])
            );
        }, []);

    return (
        <>
            <Head>
                <link rel="preconnect" href="//www.google-analytics.com" />
            </Head>
            <ContentSkip />
            {!testMode && (
                <>
                    {siteAlert && (
                        <SiteAlert
                            id={siteAlert.sys.id || siteAlert.heading}
                            icon={dynamic(() =>
                                import('@solent-university/solent-icons').then(
                                    icons => icons[siteAlert.icon.name]
                                )
                            )}
                            heading={siteAlert.heading}
                            description={htmlParser(siteAlert.description)}
                            link={
                                siteAlert.link.label && siteAlert.link.link
                                    ? {
                                          ...cmsParser.link(siteAlert.link),
                                          href: siteAlert.link.link
                                      }
                                    : undefined
                            }
                            variant={siteAlert.variant}
                        />
                    )}
                    {siteNavigation && (
                        <>
                            <Masthead
                                sites={siteNavigation.masthead.map(item => ({
                                    id: slugify(item.link.label, { lower: true }),
                                    link: { ...cmsParser.link(item.link), nofollow: item.nofollow },
                                    name: item.link.label,
                                    isActive: item.currentWebsite
                                }))}
                            />
                            <TopBar
                                logo={{
                                    image: {
                                        default: {
                                            src:
                                                logo === 'white'
                                                    ? '/graphics/logo/rebrandLogoSticky.svg'
                                                    : '/graphics/logo/rebrandLogo.svg',
                                            alt: 'Solent Unviersity Southampton logo'
                                        },
                                        sticky: {
                                            src: '/graphics/logo/rebrandLogoSticky.svg',
                                            alt: 'Solent University logo'
                                        }
                                    },
                                    link: {
                                        ariaLabel: 'Solent',
                                        href: '/'
                                    }
                                }}
                                button={{
                                    href: 'https://www.solent.ac.uk/open-days',
                                    label: 'Book an open day',
                                    ariaLabel: 'Book on to one of our open day events'
                                }}
                                shortLabel="Open days"
                                siteNavigation={{
                                    items: siteNavigation.navigationGroups.map(group => ({
                                        id: group.sys.id,
                                        name: group.heading,
                                        subnavigation: {
                                            label: group.heading,
                                            primaryNavigation: {
                                                links: group.primaryItems.map(item => ({
                                                    ...cmsParser.link(item),
                                                    id: slugify(item.label, { lower: true }),
                                                    icon: dynamic(() =>
                                                        import(
                                                            '@solent-university/solent-icons'
                                                        ).then(icons => icons[item.icon.name])
                                                    ),
                                                    iconColor: cmsParser.getThemeColour(
                                                        item.iconColor
                                                    )
                                                }))
                                            },
                                            secondaryNavigation: {
                                                heading: group.secondaryHeading,
                                                links: group.secondaryItems.map(item => ({
                                                    ...cmsParser.link(item),
                                                    id: slugify(item.label, { lower: true }),
                                                    tag: item?.tag || undefined
                                                }))
                                            },
                                            promotion:
                                                group.promotionalIsEnabled &&
                                                group.promotionalLink.length > 0
                                                    ? {
                                                          image: cmsParser.image(
                                                              group.promotionalImage
                                                          ),
                                                          text: group.promotionalHeading,
                                                          link: cmsParser.link(
                                                              group.promotionalLink[0]
                                                          )
                                                      }
                                                    : undefined
                                        }
                                    }))
                                }}
                            />
                        </>
                    )}
                    {((clearingBanner && clearingBanner.enableBanner2024) ||
                        (clearingBanner &&
                            clearingBanner.enableBannerClearingPage2024 &&
                            pathname === '/clearing')) && (
                        <Banner
                            enableBanner
                            isClearingOpen={clearingBanner.isClearingOpen}
                            clearingStatusText={clearingBanner.clearingStatusText}
                            clearingStatusTextRed={clearingBanner.clearingStatusTextRed}
                            contactLinks={clearingBanner.contactLinks.map(contact => ({
                                ...cmsParser.link(contact),
                                id: contact.link.sys.id,
                                variant: 'whiteSiteBanner',
                                icon: dynamicIcon(contact.icon.name),
                                onClick: undefined
                            }))}
                            highlightedCTA={{
                                ...cmsParser.link(clearingBanner.highlightedCTA),
                                id: clearingBanner.highlightedCTA.link.sys.id,
                                icon: dynamicIcon(clearingBanner.highlightedCTA.icon.name),
                                variant: 'siteBanner',
                                onClick: undefined
                            }}
                            preClearingCTA={{
                                ...cmsParser.link(clearingBanner.preClearingCTA),
                                id: clearingBanner.preClearingCTA.link.sys.id,
                                icon: dynamicIcon(clearingBanner.preClearingCTA.icon.name),
                                variant: 'siteBanner',
                                onClick: undefined
                            }}
                            enableApplicationModal={clearingBanner.enableApplicationModal}
                            applyModal={{
                                heading: clearingBanner.applicationModal.heading,
                                ukApply: {
                                    ...cmsParser.link(clearingBanner.applicationModal.ukStudents),
                                    variant: 'default',
                                    icon: dynamic(() =>
                                        import('@solent-university/solent-icons').then(
                                            icons =>
                                                icons[
                                                    clearingBanner.applicationModal.ukStudents.icon
                                                        .name
                                                ]
                                        )
                                    ),
                                    onClick: undefined
                                },
                                internationalApply: {
                                    ...cmsParser.link(
                                        clearingBanner.applicationModal.internationalStudents
                                    ),
                                    variant: 'white',
                                    icon: dynamic(() =>
                                        import('@solent-university/solent-icons').then(
                                            icons =>
                                                icons[
                                                    clearingBanner.applicationModal
                                                        .internationalStudents.icon.name
                                                ]
                                        )
                                    ),
                                    // TODO: Why is this needed?
                                    onClick: undefined
                                }
                            }}
                        />
                    )}
                </>
            )}
            {children}
            <ScrollToTop />
            {isPreview && <PreviewStatus />}
            {!testMode && siteFooter && (
                <Footer
                    socialLinks={Object.fromEntries(
                        siteFooter.socialLinks.map(item => [item.socialMediaType, item.link])
                    )}
                    awards={siteFooter.corporateAwards.map(item => ({
                        image: cmsParser.image(item.image),
                        link: cmsParser.link(item),
                        id: nanoid()
                    }))}
                    navigation={siteFooter.footerNavigationGroups.map(group => ({
                        label: group.heading,
                        items: group.items.map(item => ({
                            ...cmsParser.link(item),
                            id: slugify(item.label, { lower: true })
                        }))
                    }))}
                    governanceNavigation={siteFooter.governanceLinks.map(item => ({
                        ...cmsParser.link(item),
                        id: slugify(item.label, { lower: true })
                    }))}
                />
            )}
            <Script id="script-tag-manager">
                {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':` +
                    `new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],` +
                    `j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=` +
                    `'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);` +
                    `})(window,document,'script','dataLayer','GTM-TS9QG9');`}
            </Script>
            <Script
                id="script-landbot"
                strategy="lazyOnload"
                src="https://static.landbot.io/landbot-3/landbot-3.0.0.js"
                onLoad={setupLandbot}
            />
        </>
    );
};

export default Main;
